import Image from 'next/image';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomFlagOption = ({ innerProps, data }: { innerProps: any; data: any }) => (
	<div {...innerProps} className="flex-sc h-8 px-2">
		<Image
			src={
				data.flag
					? `https://flagcdn.com/${data.flag.toLowerCase()}.svg`
					: 'https://flagcdn.com/is.svg'
			}
			width={32}
			height={24}
			className="mr-1 h-6 w-8"
			alt="flag"
		/>
		<p
			className="w-12 overflow-ellipsis whitespace-nowrap text-left text-sm capitalize"
			style={{ minWidth: 36 }}
		>
			{data.code}
		</p>
		<p className="w-12 overflow-ellipsis whitespace-nowrap text-left text-sm capitalize">
			{data.fullname.toLowerCase()}
		</p>
	</div>
);

export default CustomFlagOption;
