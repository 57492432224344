import React, { ReactNode } from 'react';
import { useFormStatus } from 'react-dom';

type Props = {
	onClick?: () => void;
	type?: 'button' | 'submit' | 'reset' | undefined;
	className?: string;
	theme?: 'default' | 'white' | 'yellow';
	disabled?: boolean;
	children: ReactNode;
};

const Button = ({
	onClick,
	type = 'button',
	className = '',
	theme = 'default',
	disabled,
	children,
}: Props) => {
	const { pending } = useFormStatus();

	const baseClassNames = 'flex-cc w-full gap-3 rounded-full py-[14px] font-bold uppercase';

	const themeClassNames = (() => {
		switch (theme) {
			case 'white':
				return `bg-white text-black ${pending || disabled ? '' : 'hover:bg-theme-green hover:!text-white'}`;
			case 'yellow':
				return ' bg-theme-yellow text-white hover:bg-theme-white hover:!text-theme-green';
			case 'default':
			default:
				return `bg-theme-green text-white border border-white ${pending || disabled ? '' : 'hover:bg-white hover:border hover:border-theme-green hover:text-theme-green'}`;
		}
	})();
	return (
		<button
			type={type}
			onClick={onClick}
			data-loading={pending || disabled}
			disabled={pending || disabled}
			className={`${baseClassNames} ${themeClassNames} ${className}`}
		>
			{children}
			<i className="spinner"></i>
		</button>
	);
};

export default Button;
