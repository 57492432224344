import clsx from 'clsx';
import { forwardRef, useId, useLayoutEffect, useState } from 'react';

interface TextAreaFormProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	name: string;
	error?: boolean;
	errorMessage?: string;
	valid?: boolean;
	label?: string;
	className?: string;
	inputClassName?: string;
	optional?: boolean;
	validationOnBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => string | null;
}

const TextAreaForm = forwardRef<HTMLTextAreaElement, TextAreaFormProps>(
	(
		{
			name,
			error = false,
			errorMessage = '',
			valid,
			className,
			label,
			optional = false,
			inputClassName,
			validationOnBlur,
			...props
		},
		ref,
	) => {
		const [currentError, setCurrentError] = useState<boolean>(error);
		const [currentErrorMessage, setCurrentErrorMessage] = useState<string>(errorMessage);
		const id = useId();

		useLayoutEffect(() => {
			setCurrentError(error);
			setCurrentErrorMessage(errorMessage);
		}, [error, errorMessage]);

		const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
			let validationError = validationOnBlur ? validationOnBlur(e) : null;
			const value = e.target.value;
			if (!optional && !value) {
				validationError = errorMessage;
			}
			setCurrentError(!!validationError);
			setCurrentErrorMessage(validationError || '');
		};

		return (
			<div className={clsx('w-full', className)}>
				{label && (
					<label className="text-sm font-medium" htmlFor={`${id}-${name}`}>
						{label}
					</label>
				)}
				<textarea
					{...props}
					className={clsx(
						'h-[52px] w-full rounded-[10px] border border-[#F0F0F0] px-3 placeholder:normal-case',
						inputClassName,
						{
							'border-[#F0F0F0]': !currentError && !valid,
							'border-theme-red': currentError,
							'border-accent': valid,
							'mt-1.5': label,
						},
					)}
					onBlur={handleBlur}
					aria-invalid={currentError}
					id={`${id}-${name}`}
					name={name}
					ref={ref}
				/>
				{currentError && currentErrorMessage && (
					<div className="mt-2 text-sm text-theme-red">{currentErrorMessage}</div>
				)}
			</div>
		);
	},
);

export default TextAreaForm;
