/* eslint-disable @typescript-eslint/no-explicit-any */
import Image from 'next/image';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomFlagSelection = ({ innerProps, data }: { innerProps: any; data: any }): JSX.Element => (
	<div {...innerProps} className="flex-sc">
		<Image
			src={
				data.flag
					? `https://flagcdn.com/w80/${data.flag.toLowerCase()}.png`
					: 'https://flagcdn.com/w80/is.png'
			}
			width={32}
			height={24}
			className="mr-1 h-6 w-8 rounded-[3px]"
			alt="flag"
		/>
		<p
			className="w-12 overflow-ellipsis whitespace-nowrap text-left text-sm capitalize"
			style={{ minWidth: 36 }}
		>
			{data.code}
		</p>
	</div>
);

export const customStyles: any = {
	control: (styles: any) => ({
		...styles,
		width: '100%',
		height: '100%',
		border: 'none',
		background: '#FAFAFA',
		borderRadius: '10px',
	}),
	indicatorSeparator: (styles: any) => ({
		...styles,
		display: 'none',
	}),
	menu: (styles: any) => ({
		...styles,
		width: '150%',
	}),
	menuList: (styles: any) => ({
		...styles,
		overflowX: 'hidden',
	}),
	valueContainer: (styles: any) => ({
		...styles,
		position: 'relative',
		width: '100%',
		height: '100%',
		overflowX: 'hidden',
	}),
	input: (styles: any) => ({
		...styles,
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
	}),
};

export default CustomFlagSelection;
