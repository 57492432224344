'use client';

import { Content } from '@prismicio/client';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';
import { useFormik } from 'formik';
import Link from 'next/link';
import React, { useState } from 'react';
import Select from 'react-select';
import * as Yup from 'yup';

import upsell, { COUNTRY_CODES } from '@monorepo/upsell-kit';

import Button from '../../components/_shared/Button';
import InputForm from '../../components/_shared/InputForm';
import CustomFlagOption from '../../components/_shared/Select/FlagOption';
import CustomFlagSelection, {
	customStyles as customSelectStyles,
} from '../../components/_shared/Select/FlagSelection';
import TextAreaForm from '../../components/_shared/TextAreaForm';
import sendEmail from '../../lib/sendEmail';

/**
 * Props for `Contact`.
 */
export type ContactProps = SliceComponentProps<Content.ContactSlice>;

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Component for "Contact" Slices.
 */
const Contact = ({ slice }: ContactProps): JSX.Element => {
	const { data } = upsell.data.useBranches();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);

	const customStylesStore = {
		control: (styles: any) => ({
			...styles,
			width: '100%',
			height: '100%',
			border: 'none',
			background: '#FFFFFF',
			borderRadius: '10px',
		}),
		indicatorSeparator: (styles: any) => ({
			...styles,
			display: 'none',
		}),
		menu: (styles: any) => ({
			...styles,
			width: '100%',
		}),
		valueContainer: (styles: any) => ({
			...styles,
			position: 'relative',
			width: '100%',
			height: '100%',
			overflowX: 'hidden',
		}),
		input: (styles: any) => ({
			...styles,
			position: 'absolute',
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'start',
			alignItems: 'center',
		}),
	};

	const formik = useFormik({
		initialValues: {
			given_names: '',
			surname: '',
			code_phone: '+354',
			code_country: '+354',
			mobile_phone: '',
			subway_store: '',
			email: '',
			message: '',
		},
		validationSchema: Yup.object({
			given_names: Yup.string().required('First name is needed'),
			surname: Yup.string().required('Last name is needed'),
			subway_store: Yup.string().required('Store is needed'),
			mobile_phone: Yup.string().required('Phone is needed'),
			email: Yup.string().email('Invalid email format').required('Email is needed'),
			// time_and_date_delivery: Yup.string().required('Date is needed'),
			message: Yup.string().required('Message is needed'),
		}),
		onSubmit: async (values, actions) => {
			setIsLoading(true);

			const emailData = {
				given_names: values.given_names,
				surname: values.surname,
				code_phone: values.code_phone,
				code_country: values.code_country,
				mobile_phone: values.mobile_phone,
				subway_store: values.subway_store,
				message: values.message,
				email: values.email,
			};

			const sendEmailAttempt = await sendEmail(emailData);

			setIsLoading(false);

			if (sendEmailAttempt.success === true) {
				setIsSuccess(true);
				actions.resetForm();
			} else {
				alert('Failed to send your message!');
			}
		},
	});

	const formattedOptions = data?.map((branch) => ({
		value: branch._id,
		label: branch.business_name,
	}));

	return (
		<div className="flex h-full min-h-screen w-full bg-theme-white-dark pb-24 pt-24">
			<section className="flex-cc container w-full flex-col gap-6 md:w-3/5">
				{!isSuccess && (
					<>
						<div className="flex-cc mb-4 flex w-full flex-col gap-4">
							<h1 className="m-0 text-[40px] font-bold">{slice.primary.title}</h1>
							<PrismicRichText
								field={slice.primary.description}
								components={{
									paragraph: ({ children }) => (
										<p className="text-center text-base text-secondary lg:w-3/5">
											{children}
										</p>
									),
								}}
							/>
						</div>
						<form
							onSubmit={formik.handleSubmit}
							className="flex w-full flex-col gap-4 rounded-lg bg-white p-6 lg:w-4/5"
						>
							{/* First section */}
							<div className="grid w-full grid-cols-1 gap-5 lg:grid-cols-2">
								<InputForm
									type="text"
									name="given_names"
									label="Fornafn"
									placeholder="Sláðu inn nafn þitt"
									value={formik.values.given_names}
									error={
										!!(formik.touched.given_names && formik.errors.given_names)
									}
									errorMessage={formik.errors.given_names}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
								<InputForm
									type="text"
									name="surname"
									label="Eftirnafn"
									placeholder="Sláðu inn nafn þitt"
									value={formik.values.surname}
									error={!!(formik.touched.surname && formik.errors.surname)}
									errorMessage={formik.errors.surname}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
							</div>
							{/* Second section */}
							<div className="grid w-full gap-4 lg:grid-cols-2">
								{/* Store */}
								<div className="flex w-full flex-col">
									<label
										className="mt-1 text-sm font-medium"
										htmlFor="subway_store"
									>
										Subway verslun
									</label>
									<div className="flex-cc mt-1.5 flex  h-[52px] w-full flex-row gap-4">
										<div
											className={`flex-cc h-full w-full rounded-[10px] border border-[#F0F0F0] sm:w-full ${formik.touched.subway_store && formik.errors.subway_store && '!border-red-500'}`}
										>
											<Select
												className="flex-cc h-full !w-full !cursor-pointer"
												styles={customStylesStore}
												isSearchable
												name="subway_store"
												placeholder="Veldu verslun"
												value={formattedOptions?.find(
													(option) =>
														option.value === formik.values.subway_store,
												)}
												onChange={(selectedOption) => {
													formik.setFieldValue(
														'subway_store',
														selectedOption?.value,
														true,
													);
												}}
												options={formattedOptions}
												onBlur={() =>
													formik.setFieldTouched('subway_store', true)
												}
											/>
										</div>
									</div>
									{formik.touched.subway_store && formik.errors.subway_store && (
										<div className="mt-2 text-sm text-theme-red">
											{formik.errors.subway_store}
										</div>
									)}
								</div>

								{/* Phone */}
								<div className="flex w-full flex-col">
									<label
										className="mt-1 text-sm font-medium"
										htmlFor="mobile_phone"
									>
										Símanúmer
									</label>
									<div className="flex-cc mt-1.5 flex flex-row gap-4">
										<div
											className={`flex-cc h-full w-[50.5%] rounded-[10px] border border-[#F0F0F0] sm:w-[55%]`}
										>
											<Select
												className="flex-cc h-full w-full"
												styles={customSelectStyles}
												isSearchable
												name="code"
												placeholder="code"
												value={COUNTRY_CODES.find(
													(item) =>
														item.code === formik.values.code_phone,
												)}
												onChange={(data) => {
													formik.setFieldValue(
														'code_phone',
														data?.code ?? '+354',
														true,
													);
													formik.setFieldValue(
														'code_country',
														data?.fullname.toLowerCase() ?? '+354',
														true,
													);
												}}
												options={COUNTRY_CODES.sort((a, b) =>
													a.fullname.localeCompare(b.fullname),
												)}
												components={{
													SingleValue: CustomFlagSelection,
													Option: CustomFlagOption,
												}}
												onBlur={() =>
													formik.setFieldTouched('code_phone', true)
												}
											/>
										</div>

										<InputForm
											type="text"
											name="mobile_phone"
											placeholder="Sláðu inn símanúmer þitt"
											value={formik.values.mobile_phone}
											error={
												!!(
													formik.touched.mobile_phone &&
													formik.errors.mobile_phone
												)
											}
											errorMessage={''}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
										/>
									</div>

									{formik.touched.mobile_phone && formik.errors.mobile_phone && (
										<div className="mt-2 text-sm text-theme-red">
											{formik.errors.mobile_phone}
										</div>
									)}
								</div>
							</div>
							{/* Date and time */}
							<div className="grid w-full grid-cols-1 gap-4">
								<InputForm
									type="text"
									name="email"
									label="Email"
									placeholder="your email"
									value={formik.values.email}
									error={!!(formik.touched.email && formik.errors.email)}
									errorMessage={formik.errors.email}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
							</div>
							{/* Message */}
							<div className="grid w-full grid-cols-1 gap-4">
								<TextAreaForm
									name="message"
									label="Skilaboð"
									inputClassName="!h-[160px] py-2"
									placeholder="Sláðu inn skilaboðin þitt"
									value={formik.values.message}
									error={!!(formik.touched.message && formik.errors.message)}
									errorMessage={formik.errors.message}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
								/>
							</div>

							<div className="flex-ec flex">
								<Button
									type="submit"
									disabled={isLoading}
									theme="default"
									className="!font-medium"
								>
									Senda skilaboð
								</Button>
							</div>
						</form>
					</>
				)}

				{isSuccess && (
					<div className="flex-cc min-h-[438px] w-full rounded-[10px] bg-white">
						<div className="flex-cc mx-auto w-3/4 flex-col -lg:w-full -lg:p-6">
							<h2 className="mb-4 mt-6 text-center font-sans text-3xl -lg:mb-2 -lg:mt-4 -lg:text-2xl">
								Skilaboðin hafa verið send
							</h2>
							<p className="mb-5 text-center text-sm text-theme-gray">
								Takk fyrir að senda okkur skilaboð, við munum svara skilaboðum þínum
								eins fljótt og auðið er.
							</p>

							<Link href="/" className="inline-block cursor-pointer">
								<button
									type="button"
									className="cursor-pointer rounded-full border-0 bg-theme-green-dark px-8 py-5 font-medium uppercase text-white transition-all hover:bg-theme-yellow active:scale-95"
								>
									TIL BAKA Á FORSÍÐU
								</button>
							</Link>
						</div>
					</div>
				)}
			</section>
		</div>
	);
};

export default Contact;
